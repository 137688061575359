<template>
  <div>
    <el-drawer
      :visible.sync="enterpriseDrawer"
      :with-header="true"
      @close="close"
      :show-close="false"
      :size="size"
      @open="openStaff()"
    >
      <div slot="title" class="title_stafDrawer">
        <div class="stafDrawer_top">
          <span>{{ name }}</span>
          <span>注册时间：{{ regDate }}</span>
        </div>
        <span @click="close" class="back">返回</span>
      </div>
      <div class="stafDrawer_demo">
        <div class>
          <span style="font-size:12px">开户行：</span>
          <el-select v-model="bankDeposit" placeholder="请选择" size="mini">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div style="display: flex;align-items:center;margin-top:20px">
        <div class="stafDrawer_demo" v-if="detaShow">
          <span style="font-size:12px">自定义时间：</span>
          <el-date-picker
            v-model="customDate"
            size="mini"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
        <div class="stafDrawer_demo" v-else>
          <div style="display: flex;align-items: center;justify-content: space-between;">
            <span style="font-size:12px">数据时间：</span>
            <el-checkbox-group v-model="dateValue">
              <el-checkbox
                v-for="item in dateList"
                :label="item.value"
                :key="item.value"
              >{{ item.label }}</el-checkbox>
            </el-checkbox-group>
            <el-popover placement="top" width="600" v-model="visibleTwo">
              <el-checkbox-group v-model="optionsVlaue">
                <el-checkbox
                  :style="{ 'width': (item.label.includes('月') ? '15%' : '100%') }"
                  v-for="item in optionsList"
                  :label="item.value"
                  :key="item.value"
                  @change="checked => optionsChange(checked, item)"
                >
                  <span>{{ item.label }}</span>
                </el-checkbox>
              </el-checkbox-group>
              <el-button
                slot="reference"
                style="margin-left:10px;font-size:14px;color:#1989fa"
                type="text"
              >更多时间></el-button>
            </el-popover>
          </div>
        </div>
        <el-button size="mini" type="text" @click="switchDate">切换</el-button>
      </div>

      <div class="stafDrawer_demo">
        <el-button size="mini" type="primary" @click="searchList">查询</el-button>
        <div>
          <el-popover placement="top" width="600" v-model="visible" trigger="manual">
            <div
              style="display: flex;align-items: center;justify-content: space-between;padding: 10px;background: #eaf5ff;"
            >
              <span>选择导出信息</span>
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
              >全选</el-checkbox>
            </div>
            <div style="margin: 10px;display: flex;">
              <div style="width:25%">
                <span>时间：</span>
                <el-checkbox-group v-model="dateValueTwo">
                  <el-checkbox v-for="item in dateCheckbox" :label="item.value" :key="item.value">
                    {{ item.label }}
                    <span v-if="item.label === '今日' || item.label === '昨日'">新增</span>
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div style="width:25%">
                <span>员工数据：</span>
                <el-checkbox-group v-model="userCheckbox">
                  <el-checkbox
                    v-for="item in userCheckboxList"
                    :label="item.value"
                    :key="item.value"
                  >{{ item.label }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div style="width:25%">
                <span>合同数据：</span>
                <el-checkbox-group v-model="contentCheckbox">
                  <el-checkbox
                    v-for="item in contentCheckboxList"
                    :label="item.value"
                    :key="item.value"
                  >
                    {{ item.label
                    }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div style="width:25%">
                <span>支付数据：</span>
                <el-checkbox-group v-model="payCheckbox">
                  <el-checkbox
                    v-for="item in payCheckboxList"
                    :label="item.value"
                    :key="item.value"
                  >{{ item.label }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div
              style="display: flex;align-items: center;justify-content: space-around; margin: 20px 0"
            >
              <el-button type="primary" size="mini" :disabled="excelShow" @click="exportExcel">确定导出</el-button>
              <el-button size="mini" @click="visible = false">取消</el-button>
            </div>
            <el-button
              style="font-size:14px;color:#1989fa; margin-left: 10px"
              type="text"
              slot="reference"
              @click="getReference"
            >导出数据</el-button>
          </el-popover>
          <span style="color: #b2aabd;margin-left: 10px;font-size: 12px;margin-top:20px">
            ( 导出数据的时间选择项以下面列表的时间为基础
            )
          </span>
        </div>
      </div>
      <div class="stafDrawer_demo" style="margin-top:20px">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="类目" align="right" label-class-name="labelRight" width="150">
            <el-table-column label="时间" align="left" label-class-name="labelLeft" width="130">
              <template slot-scope="scope">
                <div :style="scope.row.style">
                  <span
                    v-if="scope.row.time === 'today' || scope.row.time === 'yesterday' || scope.row.time === 'week' || scope.row.time === 'all'"
                  >{{ scope.row.time | dateStateVal }}</span>
                  <span v-else>{{ scope.row.time }}</span>
                </div>
                <!-- <div
                >
                  <span v-if="scope.row.time === 'all'" style="font-weight:700">
                 
                  </span>
                  <span v-else>
                    {{ scope.row.time | dateStateVal }}
                  </span>
                </div>
                <div v-else>
                  <span style="font-weight: 700;" v-if="scope.row.time.length === 4">
                    {{ scope.row.time }}
                  </span>
                  <span v-else>
                    {{ scope.row.time }}
                  </span>

                </div>-->
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="员工数据">
            <template slot-scope="scope">
              <div :style="scope.row.style">
                <div style="text-align: left">注册：{{ scope.row.reg }}，认证：{{ scope.row.auth }}，</div>
                <div
                  style="text-align: left"
                >平台开户：{{ scope.row.pingtai }}，授权开户：{{ scope.row.shouquan }}，</div>
                <div style="text-align: left">激活：{{ scope.row.active }}，二升一：{{ scope.row.up }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="合同数据">
            <template slot-scope="scope">
              <div :style="scope.row.style">
                <div style="text-align: left">创建：{{ scope.row.ht }}，签署完成：{{ scope.row.ht_f }}，</div>
                <div style="text-align: left">待签署：{{ scope.row.ht_uf }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="支付数据">
            <template slot-scope="scope">
              <div :style="scope.row.style">
                <div
                  style="text-align: left"
                >支付金额：￥{{ scope.row.paysum }}，项目个数：{{ scope.row.projectNum }}，</div>
                <div
                  style="text-align: left"
                >支出次数：{{ scope.row.paycount }}，支付人次：{{ scope.row.payuser }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import * as moment from 'moment';
import * as _ from 'lodash';
import { parseJsonToTable } from '/src/lib/dev';
import { api } from '/src/api/base';
export const dashboardPlatformIndex2More = api()('/dashboard.platform.index2.more.json');

export default {
  data () {
    return {
      drawer: this.enterpriseDrawer,
      bankDeposit: '',
      checkAll: false,

      dateList: [{
        label: '今日',
        value: 'today'
      },
      {
        label: '昨日',
        value: 'yesterday'
      },
      {
        label: '近7日',
        value: 'week'
      },
      {
        label: '平台累计总量',
        value: 'all'
      }],
      dateValue: ['today', 'all'],
      dateCheckbox: [{
        label: '今日新增',
        value: 'today'
      },
      {
        label: '平台累计总量',
        value: 'all'
      }],
      dateValueTwo: ['today', 'all'],
      userCheckbox: ['reg', 'auth', 'open', 'shouquan', 'active', 'up'],
      userCheckboxList: [{
        value: 'reg',
        label: '注册人数'
      },
      {
        value: 'auth',
        label: '实名注册人数'
      },
      {
        value: 'open',
        label: '平台开户人数'
      },
      {
        value: 'shouquan',
        label: '授权开户人数'
      },
      {
        value: 'active',
        label: '卡激活人数'
      },
      {
        value: 'up',
        label: '二升一申请人数'
      }
      ],
      contentCheckbox: ['ht', 'ht_f', 'ht_uf'],
      contentCheckboxList: [
        {
          value: 'ht',
          label: '创建合同份数'
        },
        {
          value: 'ht_f',
          label: '签署完成份数'
        },
        {
          value: 'ht_uf',
          label: '待签署份数'
        }
      ],
      payCheckbox: ['paysum', 'projectNum', 'paycount', 'payuser'],
      payCheckboxList: [
        {
          value: 'paysum',
          label: '支付总金额'
        },
        {
          value: 'projectNum',
          label: '创建项目个数'
        },
        {
          value: 'paycount',
          label: '支付次数'
        },
        {
          value: 'payuser',
          label: '支付人次'
        }
      ],
      checkedExpor: [],
      isIndeterminate: true,
      customDate: [],
      options: [{
        value: '',
        label: '全部银行'
      }, {
        value: 'p2',
        label: '平安银行'
      }],
      size: '50%',
      tableData: [],
      visible: false,
      optionsList: [],
      optionsVlaue: [],
      excelShow: true,
      visibleTwo: false,
      detaShow: false,
      name: '',
      regDate: ''
    }
  },
  props: {
    enterpriseDrawer: Boolean,
    code: String
  },
  mounted () {

  },
  methods: {
    removeEmptyArrayEle (arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] == undefined) {
          arr.splice(i, 1);
          i = i - 1; // i - 1 ,由于空元素在数组下标 2 位置，删除空以后，后面的元素要向前补位，
          // 这样才能真正去掉空元素,以为这句能够删掉的连续为空试试，而后思考其中逻辑
        }
      }
      return arr;
    },
    optionsChange (checked, item) {
      console.log(checked, item);
      if (checked) {
        if (!item.value.includes('-')) {
          const a = this.optionsList.map(it => {
            if (it.value.includes(item.value)) {
              return it.value
            }
          }
          )
          this.removeEmptyArrayEle(a)
          var b = a.filter(it => it != item.value)
          this.optionsVlaue = _.union(this.optionsVlaue, b)

        }
      }
      else {
        this.optionsVlaue = this.optionsVlaue.filter(it => it.value != item.value)
      }
    },
    getReference () {
      this.visible = !this.visible;
      if (this.dateValueTwo.length <= 0) {
        this.$message('选择导出的数据数据时间');
      }
    },
    switchDate () {
      this.detaShow = !this.detaShow
    },
    async searchList () {
      var List = []
      var newList = []
      var opt = this.optionsList.filter(it => this.optionsVlaue.includes(it.value))
      var optVal = this.dateList.filter(it => this.dateValue.includes(it.value))
      if (this.customDate && this.detaShow) {
        List = [...this.dateValueTwo]
        newList = [...this.dateCheckbox]
        this.dateCheckbox = [{ value: 'diy', label: '自定义时间' }]
        this.dateValueTwo = ['diy']
      }
      else {
        List = [...this.dateValue, ...this.optionsVlaue]
        newList = [...optVal, ...opt]
        this.dateValueTwo = List.filter((it, index) => List.indexOf(it) === index)
        this.dateCheckbox = newList.filter((it, index) => newList.indexOf(it) === index)
      }
      var params = {}
      if (this.detaShow) {
        if (this.customDate) {
          const List = this.customDate.map(it => [moment(it).format('YYYY-MM-DD')])
          params = {
            enterpriseCode: this.code,
            queryTag: [['diy', List.join(',')]]
          }
          this.getList(params)
        }
        else {
          this.$message('请选择自定义时间');
        }
      }
      else if (this.dateValue.length > 0) {
        if (this.optionsVlaue.length > 0) {
          const newD = this.dateValue.map(it => [it]).filter(i => i != 'all')
          params = {
            enterpriseCode: this.code,
            queryTag: [...newD, ...opt.map(it => [it.value.includes('-') ? 'month' : 'year', it.value]), this.dateValue.includes('all') ? ['all'] : null].filter(it => it != null)
          }
          this.getList(params)
        }
        else {
          params = {
            enterpriseCode: this.code,
            queryTag: [...this.dateValue.map(it => [it]).filter(i => i != 'all'), this.dateValue.includes('all') ? ['all'] : null].filter(it => it != null)
          }
          this.getList(params)
        }
      }
      else {
        if (this.optionsVlaue.length > 0) {
          const newD = this.dateValue.map(it => [it])
          params = {
            enterpriseCode: this.code,
            queryTag: [...newD, ...opt.map(it => [it.value.includes('-') ? 'month' : 'year', it.value])]
          }
          this.getList(params)
        }
        else {
          this.$message('请选择数据时间');
        }
      }

      // params.queryTag.map(it => {
      //   if (it.includes('all')) {
      //     console.log(111, '==========');
      //     params.queryTag.filter(t => t != 'all').push(it)
      //   }
      // })
      // if (params.queryTag.includes('all')) {
      //   console.log(111, '==========', params.queryTag.filter(t => t != 'all'));
      //   params.queryTag = [...params.queryTag.filter(t => t != 'all'), ['all']]
      // }
      // console.log(params.queryTag,);

    },
    async getList (params) {
      var data = await dashboardPlatformIndex2More(params)

      this.tableData = data.list.map(it => {
        if (it.item === 'all' || it.time === 'today' || it.time === 'yesterday' || it.time === 'week') {
          if (it.item === 'all') {
            // it.add()
            it.style = 'font-weight:700'
          }
          else if (it.time === 'today' || it.time === 'yesterday') {
            it.style = 'color:#efa28d'
          }
        }

        else {
          if (!it.time.includes('-')) {
            it.style = 'font-weight:700'
          }
        }
        return it

      })
      this.name = data.name
      this.excelShow = false
      this.regDate = moment(data.createTime).format('YYYY年MM月DD')
    },
    exportExcel () {
      const oldList = [...this.payCheckbox, ...this.contentCheckbox, ...this.userCheckbox]
      const List = [...this.payCheckboxList, ...this.contentCheckboxList, ...this.userCheckboxList]

      if (this.dateValueTwo.length <= 0) {
        this.$message('选择导出的数据信息');
      }
      else {
        const time = moment().format('YYYY-MM-DD');
        var data = []
        if (!this.detaShow) {
          data = this.tableData.filter(it => this.dateValueTwo.some(ele => ele === it.time))
        }
        else {
          data = this.tableData
        }
        const _data = data.map(it => {
          const times = { 'today': '今日新增', 'yesterday': '昨日新增', 'week': '近7日', 'all': '平台累计总量' }
          return {
            ...it,
            time: times[it.time] || it.time
          };
        });
        const newList = List.filter(it => oldList.some(ele => ele === it.value))
        const adv = []
        for (let a = 0; a < newList.length; a++) {
          adv[`${newList[a].label}`] = newList[a].value
        }
        console.log(adv, 'detail');
        parseJsonToTable(
          `企业员工数据统计_${time}`,
          {
            时间: 'time',
            ...adv
          },
          _data,
          true
        );
        this.visible = false;
      }

    },
    handleCheckAllChange (val) {
      this.dateValueTwo = val ? this.dateCheckbox.map(it => it.value) : [];
      this.userCheckbox = val ? this.userCheckboxList.map(it => it.value) : [];
      this.contentCheckbox = val ? this.contentCheckboxList.map(it => it.value) : [];
      this.payCheckbox = val ? this.payCheckboxList.map(it => it.value) : [];
      this.isIndeterminate = false;
    },

    async openStaff () {

      if (this.detaShow) {
        this.dateCheckbox = [{ value: 'diy', label: '自定义时间' }]
        this.dateValueTwo = ['diy']
      }
      else {
        this.dateCheckbox = [{ label: '今日新增', value: 'today' }, {
          label: '平台累计总量', value: 'all'
        }]
        this.dateValueTwo = ['today', 'all']
      }
      var listOld = new Array(moment().diff(moment('2022-3'), 'month') + 1).fill(null).map((n, i) => moment('2022-3').add(i, 'months').format('YYYY-MM')).reverse()
      var listNew = []
      listOld.forEach(n => { !listNew.includes(n.split('-')[0]) && listNew.push(n.split('-')[0]); listNew.push(n) });
      const dateList = listNew.map(it => ({ value: it, label: it.includes('-') ? it.replace('-', '年') + '月' : it + '年' }))
      this.optionsList = dateList
      this.searchList()
    },
    close () {
      this.drawer = false;
      this.$emit('close', this.drawer)
    },
  },

}
</script>
<style >
.el-table thead.is-group th {
  background: none;
}
.el-table thead.is-group tr:first-of-type th:first-of-type {
  border-bottom: none;
}

.el-table thead.is-group tr:first-of-type th:first-of-type:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 90px;
  /*这里需要自己调整，根据td的宽度和高度*/
  top: 0;
  left: 0;
  background-color: #fff;

  display: block;
  transform: rotate(-53deg);
  /*这里需要自己调整，根据线的位置*/
  transform-origin: top;
}

.el-table th:nth-last-child(2) {
  border-radius: 0 0px 0 0;
}

.el-table thead.is-group tr:last-of-type th:first-of-type:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 80px;
  /*这里需要自己调整，根据td的宽度和高度*/
  bottom: 0;
  right: 0;
  background-color: #fff;

  display: block;
  transform: rotate(-54deg);
  /*这里需要自己调整，根据线的位置*/
  transform-origin: bottom;
}
.el-table thead.is-group tr:last-of-type th:first-of-type div {
  text-align: initial;
}
/* .el-table thead.is-group tr:first-of-type th:first-of-type div{

} */
</style>
<style lang="scss" scoped>
.title_stafDrawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .stafDrawer_top {
    span:nth-child(1) {
      font-size: 20px;
      color: #333333;
    }
    span:nth-child(2) {
      color: #b2aabd;
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .back {
    color: #1989fa;
    font-size: 14px;
  }
}
.stafDrawer_demo {
  padding: 0 20px;
  display: flex;
  align-items: center;
}
</style>